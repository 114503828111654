body { min-height: 100vh; }
*:focus{
  border-color: none!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
}
img{
  max-width: 100%;
}
.section-padding{
  padding-top: 25px;
  padding-bottom: 25px;
}
.section-padding-top{
  padding-top: 25px;
}
.section-padding-bottom{
  padding-bottom: 25px;
}
