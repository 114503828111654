.btn-more{
  background-color:$brand-secondary;
  border:1px solid $brand-secondary;
  padding:13px 22px 13px 22px;
  color:$brand-primary;
  text-transform:uppercase;
  font-size:1rem;
  font-weight:600;
  width:100%;
  display:inline-block;
  text-align:center;
  &:hover{
    background-color:darken($brand-secondary,10%);
    border:1px solid darken($brand-secondary,10%);
  }
  @media screen and (max-width: $break-large){
    padding:13px 18px 13px 18px;
    font-size:.8rem;
  }
  @media screen and (max-width: $break-medium){
    padding:10px 8px 10px 8px;
    font-size:.65rem;

  }
  @media screen and (max-width: $break-xsmall){
    font-size:.9rem;
  }
}
.btn-readmore{
  background-color:$brand-secondary;
  border:1px solid $brand-secondary;
  padding:10px 22px 10px 22px;
  color:$brand-primary;
  text-transform:uppercase;
  font-size:1rem;
  font-weight:600;
  display:inline-block;
  text-align:center;
  &:hover{
    background-color:darken($brand-secondary,10%);
    border:1px solid darken($brand-secondary,10%);
  }
  @media screen and (max-width: $break-large){
    padding:13px 18px 13px 18px;
    font-size:.8rem;
  }
  @media screen and (max-width: $break-medium){
    padding:10px 8px 10px 8px;
    font-size:.65rem;

  }
  @media screen and (max-width: $break-xsmall){
    font-size:.9rem;
  }
}
.btn-ctamore{
  text-transform:uppercase;
  font-style:italic;
  font-size:1rem;
  color:$white;
  text-shadow:0px 2px $black;
  border:1px solid $white;
  padding:13px 22px 13px 22px;
  width:100%;
  display:inline-block;
  text-align:center;
  &:hover{
    background-color:$white;
    border:1px solid $white;
    color:$brand-primary;
    text-shadow:none;
    text-decoration: none;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
  }
  @media screen and (max-width: $break-large){
    padding:13px 18px 13px 18px;
    font-size:.8rem;
  }
  @media screen and (max-width: $break-medium){
    padding:10px 10px 10px 10px;
    font-size:.7rem;
  }
  @media screen and (max-width: $break-xsmall){
    font-size:.9rem;
  }
}
.cta-linktag{
  font-style:italic;
  color:$white;
  font-size:.9rem;
  text-shadow:0px 2px $black;
  &:hover{
    text-decoration: underline;
    color:$white;
    text-shadow:none;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
  }
}
.btn{
  text-decoration: none;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;

  margin: 10px auto 2px auto;
  padding: 14px 32px;
  cursor: pointer;
  font-weight: 700;
  font-family: $font-family-secondary;
  border: $text-gray-2 solid 1px;
  border-radius: 5px;

  i{
    //padding-left: 45px;
    //font-size: 16px;
  }

  &.btn-white{
    background-color: $white!important;
    color: $brand-primary!important;
    &:hover{
      background-color: $brand-primary!important;
      border: 1px solid $brand-primary!important;
      color: $white!important;
    }
  }
  &.btn-primary{
    background-color: $brand-primary!important;
    border: $brand-primary solid 1px!important;
    color: $white!important;
    border-radius: 5px;
    &:hover, &:active, &:focus{
      color: $white!important;
      background-color: $brand-secondary!important;
      border: $brand-secondary solid 1px!important;
    }
  }
  &:focus, &:active{
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
  }
}

.btn-return{
  text-transform: uppercase;
  color: $brand-secondary;
  background-color: $white;
  border-color: $brand-secondary;
}

.btn-cta-short{
  padding: 15px 35px;
  font-size: 16px;
  color: $brand-primary;
  background-color: $white;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.54);

  text-decoration: none;
  transition: color 0.3s linear;
  -webkit-transition: color 0.3s linear;
  -moz-transition: color 0.3s linear;
  transition: background-color 0.3s linear;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  i{
    padding-left: 15px;
  }
  &:hover{
    color: $white;
    background-color: $brand-primary;
  }
}


// PAGE //
.btn-pageref{
  background-image:url("../images/page-referentie-btn.jpg");
  background-repeat:no-repeat;
  background-size: cover;
  padding:35px 22px 35px 22px;
  color:$white;
  text-transform:uppercase;
  font-size:1rem;
  font-weight:600;
  width:100%;
  height:100px;
  display:inline-block;
  text-align:center;
  margin-bottom:5px;
  &:hover{
    color:$white;
    text-decoration: underline;
  }
  @media screen and (max-width: $break-xsmall){
    background-size: cover;
  }
}
.btn-pagegreenmore{
  background-color:$brand-secondary;
  padding:13px 22px 13px 22px;
  color:$brand-primary;
  font-size:1rem;
  font-weight:600;
  width:100%;
  display:inline-block;
  text-align:center;
  margin-bottom:25px;
  &:hover{
    background-color:darken($brand-secondary,10%);
  }
  @media screen and (max-width: $break-large){
    padding:13px 18px 13px 18px;
    font-size:.8rem;
  }
  @media screen and (max-width: $break-medium){
    padding:10px 8px 10px 8px;
    font-size:.65rem;

  }
  @media screen and (max-width: $break-xsmall){
    font-size:.9rem;
  }
}

// CONTACT //
.btn-form-submit{
  background-color:$brand-secondary;
  font-family:$font-family-primary;
  border:none;
  border-radius:0;
  text-transform:uppercase;
  padding:13px 22px 13px 22px;
  color:$brand-primary;
  font-size:.8rem;
  font-weight:600;
  text-align:center;
  margin-bottom:25px;
  &:hover{
    background-color:darken($brand-secondary,10%);
  }
}