.page-content{
    background-color: darken($brand-bg-primary,3%);
    padding-top:35px;
    padding-bottom:35px;
    margin-top:25px;
    .left{
        a{
            text-decoration: underline;
            &:hover{
                color:$brand-secondary;
            }
        }
    }
    .right{
        img{
            @media screen and (max-width: $break-xsmall){
                width:100%;
                height:auto;
              }
        }
        .img-minwidth{
            min-width:100%;
        }
    }
}