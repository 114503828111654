html{
    background-color:$white;
}
.header-block-menu{
    margin-top:20px;
    margin-bottom:20px;
    height:auto;
    @media screen and (max-width: $break-xsmall){
        margin-top:15px;
    }
    .header-logo{
        padding:0;
        img{
            width:160px;
            height:88px;
            @media screen and (max-width: $break-xsmall){
                width:170px;
                height:73px;  
            }
        }

    }
    .header-menu{
        margin-top:20px;
        @media screen and (max-width: $break-xsmall){
            margin-top:0; 
        }
        button{
            &:focus{
                outline:none;
            }
        }
        .main-nav{
            padding:0;
            li{
                a{
                    font-size:.8rem;
                    color:$brand-primary;
                    text-transform:uppercase;
                    font-weight:bold;
                    padding:9px;
                    @media screen and (max-width: $break-large){
                        font-size:.6rem; 
                    }   
                }
            }
            li.active{
                background-color:$brand-secondary;
            }
            
        }
    }
}
.header-home-slider{
    background-image:url("../images/divatec_header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height:359px;
    @media screen and (max-width: $break-medium){
        background-image:url("../images/divatec_header.jpg");
        background-repeat: no-repeat;
        background-position:center;
        background-size: cover;
        height:300px;
    }
    @media screen and (max-width: $break-xsmall){
        background-image:url("../images/bg_photo.png");
        background-repeat: no-repeat;
        background-size: cover;
        height:242px;
    }
    .home-slider-block-left{
        margin-bottom:15px;
        @media screen and (max-width: $break-xsmall){
            padding-left:0;
        }
        .home-title{
            h1{ 
            } 
        }
        .home-subtitle{
            h2.written-header-title{
                text-shadow: 0px 2px $black;
            }
            @media screen and (max-width: $break-xsmall){
                display:none;
            }
            @media screen and (max-width: $break-medium){
                display:none;
            }
        }
        .home-contact-btn{
            @media screen and (max-width: $break-xsmall){
                padding-bottom:0px;
            }    
            .header-padding-btn{
                margin-bottom:10px;
                @media screen and (max-width: $break-medium){
                    text-align:center;
                }
            } 
        }
        .home-contact-alt-btn{
            
        }
    }
    .home-contact-cta{
            
    }
}
.activity-block{
    margin-top:50px;
    margin-bottom:50px;
    .activity-title{
        text-align: center;
    }
    .activity-block-image{
        margin-bottom:10px;
        @media screen and (max-width: $break-xsmall){
            text-align: center;
        }
        img{
            @media screen and (max-width: $break-xsmall){
                width:200px;
                height:200px;
            }
        }
    }
    .dots{
        
    }
    .activity-block-text{
        text-align:center;
        padding:15px 0px;
        min-height:250px;
        @media screen and (max-width: $break-large){
            min-height:290px; 
        }
        @media screen and (max-width: $break-medium){
            min-height:226px;
        }
        @media screen and (max-width: $break-xsmall){
            min-height:0px;
        }
        
        h4{
            font-family:$font-family-primary;
            font-size:1.2rem;
            font-weight:600;
            color:$brand-primary;
            margin-bottom:15px;
        }
        p{
            font-weight:400;
            
        }
    }
    .activity-block-btn{
        padding-bottom:15px;
        @media screen and (max-width: $break-xsmall){
            padding-bottom:30px;
        }
        a{
            
        }
    }
}
.about-block{
    background-color: darken($brand-bg-primary,3%);
    margin-bottom:130px;
    padding:35px 35px 0 35px;
    @media screen and (min-width: $break-large){
        max-height:450px;
    }
    @media screen and (max-width: $break-medium){
        border-top:1px solid $header-intro-bg;
        border-bottom:1px solid $header-intro-bg;
        min-height:50px;
        margin-bottom:50px;
    }
    @media screen and (max-width: $break-xsmall){
        border-top:1px solid $header-intro-bg;
        border-bottom:1px solid $header-intro-bg;
        min-height:50px;
        margin-bottom:50px;
    }
    .about-block-left{
        text-align:right;
        @media screen and (max-width: $break-medium){
            text-align: left;
            padding:0;
            margin-bottom:30px;
        }
        @media screen and (max-width: $break-xsmall){
            text-align: center;
            padding:0;
            margin-bottom:30px;
        }
        p{
            &:last-of-type{
                margin-bottom:25px;
            }
        }
    }
    .about-block-image{
        position:relative;
        top:-30px;
        @media screen and (max-width: $break-xsmall){
            padding:0;
            position:static;
        }
        img{
            @media screen and (max-width: $break-xsmall){
                text-align: center;
                width:100%;
            }
        }
        
    }
    .about-block-right{
        @media screen and (max-width: $break-xsmall){
            padding:0;
          }
        ul{
            @media screen and (max-width: $break-xsmall){
                padding-left:25px;
              }
            li{
                list-style-type: none;
                line-height: 2rem;
                @media screen and (max-width: $break-xsmall){
                    font-size:.9rem;
                }
                .fa-user{
                    color:$brand-secondary;
                    padding-right:20px;
                }
            }
        }
    }
}
.why-divatec-block{
    text-align:center;
    margin-bottom:50px;
    .row{
        background-image:url("../images/why-divatec.jpg");
        background-repeat:no-repeat;
        background-size: cover;
        @media screen and (max-width: $break-xsmall){
            background-image:none;
        }
        p.p-why{
            color:$white;
            font-weight: bold;
            text-transform:uppercase;
            @media screen and (max-width: $break-xsmall){
                color:$brand-primary;
            }
        }
        .activity-item-block-1, .activity-item-block-2, .activity-item-block-3, .activity-item-block-4 {
            border-right:12px solid $white;
            min-height:100%;
            padding:30px 15px 25px 15px;
            svg{
                width:70px;
                padding-bottom:25px;
                @media screen and (max-width: $break-medium){
                    width:100px;
                    height:100px;
                }
                @media screen and (max-width: $break-xsmall){
                    width:100px;
                    height:100px;
                }
            }
            .st0{
                fill:$white;
                @media screen and (max-width: $break-xsmall){
                    fill:$brand-secondary;
                }
            }
            @media screen and (max-width: $break-xsmall){
                border:none;
            }
        }
        img{
            height:100px;
            margin-bottom:20px;
        }
    }
}
.footer-block{
    background-color:$brand-primary;
    height:235px;
    
    @media screen and (max-width: $break-xsmall){
        height:300px;
    }
    .footer-block-item{
        color:$brand-secondary;
            @media screen and (max-width: $break-medium){
                padding-right:0;
            }
        a{
            color:$brand-secondary;
            @media screen and (max-width: $break-medium){
                padding-right:0;
            }
        }
        img{
            width:50%;
            height:auto;
        }
        .col-9{
            a{
                color:$white;
                &:hover{
                    text-decoration:underline;
                }
            }
            @media screen and (max-width: $break-medium){
                padding-right:0;
            }
        }
        .fa-2x{
            @media screen and (max-width: $break-medium){
                font-size:1.8rem;
            }
        }
        p{
            color:$white;
            font-size:.8rem;
            line-height: .8rem;
            @media screen and (max-width: $break-medium){
                font-size:.6rem;
                line-height: .7rem;
            }
            @media screen and (max-width: $break-xsmall){
                font-size:.7rem;
                line-height: .7rem;
            }
        }
        
    }
}
.footer-block2{
    background-color:$brand-secondary;
    height:60px;
    p{
        font-size:.75rem;
        font-family:$font-family-primary;
        color:$brand-primary;
        text-transform: uppercase;
        margin:0;
        @media screen and (max-width: $break-medium){
            font-size:.56rem;
        }
        @media screen and (max-width: $break-xsmall){
            padding-top:10px;
            font-size:.5rem;
        }
        span{
            color:$white;
            text-transform:none;
            font-size:.85rem;
            @media screen and (max-width: $break-medium){
                font-size:.65rem;
            }
        }
        img{
            width:75px;
            height:50px;
            @media screen and (max-width: $break-xsmall){
                width:50px;
                height:23px;
            }
        }
    }
}