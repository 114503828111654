/* -------------
 * Typography
 * -------------*/


.uppercase{
  text-transform: uppercase !important;
}
.text-bold{
  font-weight: bold;
}
body{
  color: $body-text;
  font-family: $font-family-third;
}

// Headings
h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-primary;
}
h1{
  // color: $text-header-gray;
  // font-size: 3rem;
  // font-weight:100;
  // font-weight: bold;
  // text-transform: uppercase;
  font-size: 1.8rem;
  color: $brand-primary;
  margin: 10px 0 14px 0;
  font-weight: bold;
  text-transform:uppercase;
  &.hp-white{
    color: $white;
    font-size: 3.4375rem;
    margin: 10px 0 0 0;
  }
  &.h1-white{
    color: $white;
    text-transform: none;
    margin: 10px 0 0 0;
  }
}
h1.head-title{
  color:$white;
  font-family:$font-family-four;
  font-weight: lighter;
  font-size:3.7rem;
  letter-spacing: 6px;
  text-shadow: 0px 2px $black;
  @media screen and (max-width: $break-large){
    font-size:2.7rem;
  }
  @media screen and (max-width: $break-medium){
    font-size:1.9rem;
    text-align:left;
    padding-bottom:10px;
  }
  @media screen and (max-width: $break-xsmall){
    font-size:1.4rem;
    text-align:left;
    padding-bottom:10px;
  }
}
h2{
  font-size: 1.125rem;
  color: $brand-primary;
  margin: 10px 0 14px 0;
  font-weight: bold;
  text-transform:uppercase;
}
h2.written-header-title{
  font-family:$font-family-secondary;
  font-size: 1.7rem;
  color: $white;
  margin: 10px 0 30px 0;
  text-transform:initial;
  @media screen and (max-width: $break-large){
    font-size:1.3rem;
  }
}
h2.written{
  font-family:$font-family-secondary;
  font-size: 2rem;
  color: $brand-primary;
  margin: 10px 0 14px 0;
  font-weight: bold;
  text-transform: initial;
  @media screen and (max-width: $break-xsmall){
    font-size:1.3rem;
  }
}
h3{
  font-size: 1.125rem;
  color: $brand-primary;
  margin: 10px 0 14px 0;
  font-weight: bold;
  &:last-child{
    margin-bottom: 0;
  }
}
h4{
  font-size: 0.875rem;
  color: $text-gray;
  margin: 10px 0 14px 0;
}
// Text Blocks

p{
  font-family: $font-family-primary;
  color: $black;
  font-size:.9rem;
}

// Colors
.txt-orange{
  color: $brand-secondary;
}
.txt-white{
  color: $white;
}
.txt-primary{
  color: $brand-primary;
}
.txt-secondary{
  color: $brand-secondary;
}

// Styles
.text-uppercase{
  text-transform: uppercase;
}
.txt-bold{
  font-weight: bold;
}
.txt-transform-upper{
  text-transform: uppercase;
}
.txt-color-primary{
  color: $brand-primary;
}
.txt-color-secondary{
  color: $brand-secondary;
}


// Links
a {
  color: $link-color;
  text-decoration: none;
  transition: all 200ms ease-in;
  -webkit-transition: all 200ms ease-in;
  -moz-transition: all 200ms ease-in;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}


// Contextual text color
@include text-emphasis-variant('.text-primary', $brand-primary);

@include text-emphasis-variant('.text-success', $state-success-text);

@include text-emphasis-variant('.text-info', $state-info-text);

@include text-emphasis-variant('.text-warning', $state-warning-text);

@include text-emphasis-variant('.text-danger', $state-danger-text);

// Contextual background
@include bg-variant('.bg-primary', $brand-primary);

@include bg-variant('.bg-success', $state-success-bg);

@include bg-variant('.bg-info', $state-info-bg);

@include bg-variant('.bg-warning', $state-warning-bg);

@include bg-variant('.bg-danger', $state-danger-bg);


// Others
.text-uppercase{
  text-transform: uppercase;
}
.text-center{
  text-align: center;
}
.text-bold{
  font-weight: bold;
}
.text-muted-2x {
  color: lighten($bs-text-muted, 20%);
}

.text-superbig {
  font-size: 156px;
}

.bold-separator {
  border-top: 3px solid $brand-primary;
}
p{
  margin: 0 0 10px;
}
.section-title{
  padding-top: 35px;
  padding-bottom: 35px;
  font-weight: bold;
}

/*
  Listing
 */
ul.listing-repeater{
  list-style-type: none;
  padding-left:0;
  li.listing-item{
    font-weight: bold;
    &:before{
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f00c";
      padding-right: 15px;
    }
  }
}
