.form-control.is-invalid, .was-validated .form-control:invalid {
    border-bottom:1px solid $red !important;
    background-image: none;
}
.form-control.is-valid, .was-validated .form-control:valid {
    border-bottom:1px solid $brand-secondary !important;
    background-image: none;
}
.custom-select.is-invalid, .was-validated .custom-select:invalid {
    border-bottom:1px solid $red !important;
    background-image: none;
}
.custom-select.is-valid, .was-validated .custom-select:valid {
    border-bottom:1px solid $brand-secondary !important;
    background-image: none;
    background-color:darken($brand-bg-primary,6%) !important;
}
.custom-textarea.is-invalid, .was-validated .custom-textarea:invalid {
    border-bottom:1px solid $red !important;
    background-image: none;
}
.custom-textarea.is-valid, .was-validated .custom-textarea:valid {
    border-bottom:1px solid $brand-secondary !important;
    background-image: none;
}