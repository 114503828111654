.subline{
  width: 65px;
  margin: 0 auto;
  &.height-2{
    height: 2px;
  }
  &.height-5{
    height: 5px;
  }
  &.p-left{
    text-align: left;
    margin: 0;
  }
  &.p-right{
    text-align: right;
    margin: 0;
  }
  &.color-primary{
    background-color: $brand-primary;
  }
  &.color-secondary{
    background-color: $brand-secondary;
  }
}