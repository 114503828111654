.contact-content{
    background-color: darken($brand-bg-primary,3%);
    padding-top:35px;
    padding-bottom:35px;
    margin-top:25px;
    .contact-info{
        margin-bottom:25px;
    }
    .contact-info-thx{
        margin-bottom:25px;
        min-height:40vh;
        @media screen and (max-width: $break-small){
            min-height:25vh;
        }
        @media screen and (max-width: $break-xsmall){
            min-height:15vh;
        }
    }
    .left{
        form{
            .no-margin-bottom{
                margin-bottom:0;
            }
            input{
                border:none;
                border-bottom:1px solid lighten($brand-primary,50%);
                background-color:darken($brand-bg-primary,6%);
                border-radius:0;                
            }
            select{
                border:none;
                border-bottom:1px solid lighten($brand-primary,50%);
                background-color:darken($brand-bg-primary,6%);
                border-radius:0;
                margin-top:0;
                option{
                    background-color:darken($brand-bg-primary,6%);
                }
                
            }
            label{
                padding-left:15px;
                margin-bottom:0;
            }
            textarea{
                width:100%;
                border:none;
                border-bottom:1px solid lighten($brand-primary,50%);
                background-color:darken($brand-bg-primary,6%);
                border-radius:0;
                padding-left:12px;
                padding-top:15px;
                outline:none;
                &:active{
                    outline:none;
                }
            }
        }
    }
    .right{
       .right-image{
           margin-bottom:15px;
       } 
    }
}