// Colors
.bg-white{
  background-color: $white;
}
.bg-brand-primary{
  background-color: $brand-primary;
}
.bg-brand-secondary{
  background-color: $brand-secondary;
}
.bg-light-gray{
  background-color: $light-gray;
}
.bg-custom{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.bg-images-body{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $content-bg;
  .overlay{
    //background-color: rgba($black, 0.6);
  }
}
.bg-main-content{
  //background-color: rgba(193, 159, 131, 0.6);
}
#wrapper-navbar{
  .bg-custom-nav{
    //background-size: cover;
    //background-repeat: no-repeat;
    //background-position: center;
    //background-color: red;
    //background-image: url("../img/header-bg.jpg");
  }
}