.ref-container{
    margin-bottom:25px;
    .col-md-3{
        margin-bottom:25px;
    }
    h1{
        text-align:center;
        margin-bottom:25px;
        @media screen and (max-width: $break-xsmall){
            font-size:1.4rem;
        }
    }
}
// lightbox //
.ekko-lightbox {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-right: 0 !important;
  }
  
  .ekko-lightbox-container {
    position: relative;
    > div.ekko-lightbox-item {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
    }
  }
  
  .ekko-lightbox iframe {
    width: 100%;
    height: 100%;
  }
  
  .ekko-lightbox-nav-overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    a {
      -ms-flex: 1;
      flex: 1;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      opacity: 0;
      transition: opacity .5s;
      color: #fff;
      font-size: 30px;
      z-index: 1;
      > {
        * {
          -ms-flex-positive: 1;
          flex-grow: 1;
        }
        :focus {
          outline: none;
        }
      }
      span {
        padding: 0 30px;
      }
      &:last-child span {
        text-align: right;
      }
      &:hover {
        text-decoration: none;
      }
      &:focus {
        outline: none;
      }
      &.disabled {
        cursor: default;
        visibility: hidden;
      }
    }
  }
  
  .ekko-lightbox {
    a:hover {
      opacity: 1;
      text-decoration: none;
    }
    .modal-dialog {
      display: none;
    }
    .modal-footer {
      text-align: left;
    }
  }
  
  .ekko-lightbox-loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    > div {
      width: 40px;
      height: 40px;
      position: relative;
      text-align: center;
      > div {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #fff;
        opacity: .6;
        position: absolute;
        top: 0;
        left: 0;
        animation: a 2s infinite ease-in-out;
        &:last-child {
          animation-delay: -1s;
        }
      }
    }
  }
  
  .modal-dialog .ekko-lightbox-loader > div > div {
    background-color: #333;
  }
  
  @keyframes a {
    0%,
      to {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
  
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
  